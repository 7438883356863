.cost-section-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    max-width: 1500px;
    margin: 0 auto;
  }
.cost_flex{
    display: flex;
    flex-direction: column;
}
.maleta{
    width: 177px;
    margin: 0 auto;
}
  .cost-section-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .cost-image {
    width: 750px;
    height: auto;
    border-radius: 10px;
  }
  
  .cost-section-right {
    max-width: 630px;
    text-align: left;
  }
  
  .cost-title {
    color: #1A2E5E;
    font-size: 55px;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: center;
    max-width: 603px;
  }
  
  .cost-description {
    color: #1A2E5E;
    font-size: 32px;
    margin-bottom: 0px;
  }
  
  .quote-button {
    background-color: #FF6A51;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 15px 30px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  }
  
  .quote-button:hover {
    background-color: #e65a41;
  }
  
  @media (max-width: 1524px) {
    .cost-image {
      width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 1000px) {
    .cost-section-container {
      flex-direction: column;
      text-align: center;
    }
  
    .cost-section-left {
      order: 2;
      margin-bottom: 40px;
    }
  
    .cost-section-right {
      order: 1;
      text-align: center;
    }
  
    .cost-title {
      font-size: 36px;
    }
  
    .cost-description {
      font-size: 24px;
    }
  
    .quote-button {
      width: 80%;
      font-size: 24px;
    }
  
    .cost-image {
      width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .cost-title {
      font-size: 30px;
    }
  
    .cost-description {
      font-size: 20px;
    }
  
    .quote-button {
      font-size: 20px;
    }
  }
  