.autoridad-container {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f9f9f9;
    text-align: center;
    flex-direction: column;
  }
  
  .autoridad-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    position: relative;
  }
  
  .autoridad-image-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .autoridad-image {
    width: 100%;
    max-width: 1270px;
    min-width: 30px;
    height: auto;
  }
  
  .autoridad-text {
    flex: 1;
    text-align: left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%);
  }
  
  .autoridad-title {
    font-size: 40px;
    font-weight: 800;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .autoridad-description {
    font-size: 24px;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .autoridad-button {
    background-color: #FF6A51;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 15px 30px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 30px;
  }
  
  .autoridad-button:hover {
    background-color: #e65a41;
  }
  .footer_final{
    width: 100%;
    height: 93px;
    background-color: #1A2E5E;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  /* Media Queries */
  @media (max-width: 768px) {
  
    .autoridad-title {
      font-size: 10px;
    }
  
    .autoridad-description {
      font-size: 10px;
    }
  
    .autoridad-button {
      font-size: 24px;
    }
    .autoridad-button {
      font-size: 10px;
    }
  }
  