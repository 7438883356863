.quote-section-container {
    text-align: center;
    padding: 40px 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .quote-text {
    color: #1A2E5E;
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .quote-button {
    background-color: #FF6A51;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 15px 30px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  }
  
  .quote-button:hover {
    background-color: #e65a41;
  }
  
  @media (max-width: 768px) {
    .quote-text {
      font-size: 24px;
    }
  
    .quote-button {
      width: 80%;
      font-size: 24px;
    }
  }
  