.hero_header {
  height: 93px;
  width: 100%;
  background-color: #1A2E5E;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Header_left {
  width: 15%;
  height: 93px;
  background-color: #FF6A51;
  clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
}

.Header_rigth {
  height: 53px;
  width: 273px;
  margin-left: 20px;
}

.hero-content {
  position: relative;
  background-image: url('../../public/Background.png');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  height: 776px;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.hero-content h1 {
  font-size: 64px;
  max-width: 990px;
  margin: 0 20px;
}

.hero-content p {
  font-size: 36px;
  margin: 0;
}

@media (max-width: 1200px) {
  .Header_left {
    width: 20%;
  }

  .Header_rigth {
    width: 200px;
  }

  .hero-content h1 {
    font-size: 48px;
  }

  .hero-content p {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .hero_header {
    height: auto;

  }


  .Header_rigth {
    width: 150px;
    height: auto;
    margin-left: 0;
    margin-top: 10px;
  }

  .hero-content {
    height: auto;
    padding: 20px;
  }

  .hero-content h1 {
    font-size: 36px;
  }

  .hero-content p {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 28px;
  }

  .hero-content p {
    font-size: 20px;
  }
}
