.programs-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 20px;
  background-color: #F6F6F6;
}

.program-card {
  width: 447px;
  text-align: center;
  color: #1A2E5E;

  border-radius: 10px;
  margin: 20px;
}

.program-card-image {
  width: 90%;
  height: 291px;
  object-fit: cover;
  border-radius: 10px;
}

.program-card-title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 20px;
}

.program-card-description,
.program-card-price {
  font-size: 25px;
  margin: 0px 0;
}
