.adventure-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .adventure-title {
    font-size: 48px;
    font-weight: 800;
    color: #1A2E5E;
    margin-bottom: 40px;
  }
  
  .adventure-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .adventure-text {
    max-width: 650px;
    text-align: right;
    font-size: 36px;
    color: #1A2E5E;
  }
  
  .adventure-images {
    position: relative;
    max-width: 600px;
  }
  
  .image-wrapper {
    position: relative;
  }
  
  .sup-image {
    border-radius: 10px;
  }
  
  .sup-image-1 {
    max-width: 655px;
    width: 100%;
    min-width: 400px;
  }
  
  .sup-image-2 {
    max-width: 323px;
    width: 50%;
    position: absolute;
    top: -20%;
    right: 0%;
    z-index: 1;
  }
  
  .color_title {
    color: #FF6A51;
  }
  
  @media (max-width: 768px) {
    .adventure-content {
      flex-direction: column;
      text-align: center;
    }
  
    .adventure-text {
      max-width: 100%;
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .adventure-images {
      max-width: 100%;
      margin-top: 20px;
      position: relative;
    }
  
    .sup-image-1 {
        min-width: 0;
    }
}
  