.dream-container {
    text-align: center;
    padding: 40px 0px;
  }
  
  .dream-text {
    max-width: 1298px;
    margin: 0 auto;
  }
  
  
  .dream-description {
    color: #1A2E5E;
    font-size: 32px;
    max-width: 1000px;
    margin: 40px auto;
  }
  
  .dream-image-container {
    width: 100%;
  }
  
  .dream-image {
    width: 100%;

    height: auto;
  }
  
  @media (max-width: 768px) {
    .dream-title {
      font-size: 28px;
    }
  
    .dream-description {
      font-size: 24px;
    }
  }
  