.videos-container {
  text-align: center;
  padding: 40px 20px;
  background-color: #F6F6F6;
}

.videos-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.video-player {
  border: none;
  max-width: 447px;
  width: 100%;
  height: 255px;
}

.quote-button {
  background-color: #FF6A51;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 15px 30px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 40px;
}

.quote-button:hover {
  background-color: #e65a41;
}

@media (max-width: 768px) {
  .video-player {
    max-width: 100%;
    height: auto;
  }
}
