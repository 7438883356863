.testimonial-container {
  text-align: center;
  padding: 40px 20px;
}

.testimonial-title {
  color: #1A2E5E;
  font-size: 48px;
  font-weight: 800;
  margin-bottom: 40px;
  max-width: 1200px;
  margin: 40px auto;
}

.video-wrapper {
  max-width: 900px;
  margin: 0 auto;

}
.testimonial-video {
  width: 100%;
  height: 500px;
  margin-top: 5%;
  box-shadow: -20px 40px 30px rgba(0, 0, 0, 0.4);
}

@media (max-width: 768px) {
  .testimonial-title {
    font-size: 36px;
  }

  .testimonial-video {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .testimonial-title {
    font-size: 24px;
  }

  .testimonial-video {
    height: 200px;
  }
}
