.container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
  padding: 20px;
}

.expert-profile-container {
  display: flex;
  align-items: center;
  max-width: 980px;
  background-color: #1A2E5E;
  border-radius: 20px;
  padding: 20px;
  color: #fff;
  width: 100%;
}

.expert-image {
  flex: 1;
  width: 439px;
  height: 331px;
  position: relative;
  margin-bottom: 60px;
}

.expert-image img {
  width: 130%;
  height: auto;
  top: 0%;
  left: -35%;
  position: absolute;
  border-radius: 10px;
}

.expert-details {
  flex: 2;
  padding: 20px;
  text-align: left; /* Asegura que el texto esté alineado a la izquierda */
}

.expert-details h1 {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  margin-bottom: 20px;
}

.expert-details p {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 10px;
  max-width: 580px;
}

.raya4 {
  width: 20%;
  margin-top: 20px;
  height: 3px;
  background-color: white;
}

/* Media query para pantallas menores a 800px */
@media (max-width: 900px) {
  .expert-profile-container {
    flex-direction: column;
    text-align: center;
  }

  .expert-details {
    order: 1;
    text-align: center;
  }

  .expert-image {
    order: 2;
    width: 100%;
    height: auto;
    margin-top: 20px;
    position: static;
  }

  .expert-image img {
    width: 50%;
    height: auto;
    left: 0;
    top: 0;
    position: relative;
  }

  .expert-details h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .expert-details p {
    font-size: 20px;
    line-height: 28px;
    max-width: 100%;
  }

  .raya4 {
    width: 100%;
  }
}
