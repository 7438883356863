.destinations-container {
    margin: 0 auto;
    padding: 40px 20px;
    text-align: center;
  }
  
  .destinations-title {
    color: #1A2E5E;
    max-width: 1220px;
    font-size: 36px;
    font-weight: 800;
    margin-bottom: 20px;
    margin: 0 auto;
  }
  
  .destinations-description {
    color: #1A2E5E;
    font-size: 32px;
    margin-bottom: 40px;
    max-width: 1220px;
    margin: 50px auto;
  }
  
  .destinations-description strong {
    font-weight: 800;
  }
  
  .destinations-images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .destination-image {
    width: 370px;
    height: 291px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    .destinations-title {
      font-size: 28px;
    }
  
    .destinations-description {
      font-size: 24px;
    }
  
    .destination-image {
      width: 100%;
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .destinations-title {
      font-size: 24px;
    }
  
    .destinations-description {
      font-size: 20px;
    }
  }
  